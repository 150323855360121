export default {
  version: "2.4.0",
  baseUrl: "https://api.t4t-api.org/api/",
  // baseUrl: "https://api-stager.t4t-api.org/api/",
  baseUrlUpload: "https://geko-asset.t4t-api.org",
  baseUrlExport: "https://geko-export.t4t-api.org/",
  baseUrlNursery: "https://api-nursery.t4t-api.org/api",
  mapBoxApi:
    "pk.eyJ1Ijoiam9oYW5yb2VkIiwiYSI6ImJVYmoyTUEifQ.1xKUxaVIFEmtC7IusBQ88g",
  mapBoxStyle: "mapbox://styles/mapbox/satellite-streets-v12",
  mapBoxStyleLight: "mapbox://styles/mapbox/light-v11",
  programYear: {
    model: "2024",
    options: ["2020", "2021", "2022", "2023", "2024"],
  },
};
